import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { graphql, Link as RouterLink } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box, Button, Container, Hidden, Typography } from '@mui/material';
import { FaArrowCircleRight } from 'react-icons/fa';
import Layout from '../layout';
import Seo from '../components/Seo';
import CenteredContainer from '../components/CenteredContainer';
import HeroSlider from '../components/HeroSlider';
import FaqAccordion from '../components/FaqAccordion';
import CallToAction from '../components/CallToAction';

const IndexPage = ({ data }) => {
  const { strapiNaslovna, strapiCestaPitanja } = data;

  return (
    <Box minHeight="280vh">
      <Layout>
        <Seo title="MKF Mikra" />
        <LandingWrapper>
          <HeroSlider slides={strapiNaslovna.heroSlides} />
        </LandingWrapper>
        <CenteredContainer>
          <Typography variant="h2" sx={{ textAlign: 'center' }} gutterBottom>
            {strapiNaslovna.koraciNaslov}
          </Typography>
          <Typography variant="overline" color="primary">
            {strapiNaslovna.koraciPodnaslov}
          </Typography>
          <StepContainer>
            {strapiNaslovna.koraci.map(({ id, naslov, podnaslov, ikona }, index) => (
              <div key={index}>
                <Step>
                  <img src={ikona.localFile.publicURL} alt={`${naslov} slika`} />
                  <Typography variant="h5" sx={{ mt: 2 }}>
                    {index + 1}. {naslov}
                  </Typography>
                  <Typography variant="subtitle1">{podnaslov}</Typography>
                </Step>
                {index !== strapiNaslovna.koraci.length - 1 && (
                  <Hidden xlDown implementation="css">
                    <Box sx={{ mx: 3, my: 'auto' }}>
                      <FaArrowCircleRight key={`${id}-${index}`} fontSize={36} color="inherit" />
                    </Box>
                  </Hidden>
                )}
              </div>
            ))}
          </StepContainer>
          <PromoContainer>
            <Box sx={{ textAlign: 'left', flex: '1', pr: [0, 6] }}>
              <Typography variant="overline" color="primary" gutterBottom>
                {strapiNaslovna.promoPodnaslov}
              </Typography>
              <Typography variant="h3">{strapiNaslovna.promoNaslov}</Typography>
              <Box sx={{ mt: 2, mb: 4, pr: [0, 6] }}>
                <Typography variant="subtitle1" gutterBottom>
                  {strapiNaslovna.promoText}
                </Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                component={RouterLink}
                to={strapiNaslovna.promoAkcija.url}
                state={{ promocode: strapiNaslovna.promoAkcija.iMikroSifraAkcije }}
              >
                {strapiNaslovna.promoAkcija.text}
              </Button>
            </Box>
            <Box sx={{ flex: 1, minWidth: 282, pb: 4 }}>
              <GatsbyImage
                loading="lazy"
                image={strapiNaslovna.promoSlika.localFile.childImageSharp.gatsbyImageData}
                alt=""
              />
            </Box>
          </PromoContainer>
        </CenteredContainer>
        <Container sx={{ bgcolor: 'background.block' }} maxWidth={false} disableGutters>
          <CenteredContainer maxWidth={720}>
            <Box sx={{ textAlign: 'center', minHeight: 310 }}>
              <img src={strapiCestaPitanja.ikona.localFile.publicURL} alt="" />
              <Typography variant="h2" sx={{ mt: 3 }} gutterBottom>
                {strapiCestaPitanja.naslov}
              </Typography>
              <Typography variant="subtitle1">{strapiCestaPitanja.podnaslov}</Typography>
            </Box>
            <Box sx={{ my: 7 }}>
              {strapiCestaPitanja.faq.slice(0, 3).map(({ id, odgovor, pitanje }) => (
                <FaqAccordion key={id} question={pitanje} answer={odgovor} />
              ))}
            </Box>
            <Button variant="contained" color="primary" component={RouterLink} to="/pitanja-i-odgovori">
              Vidi sva pitanja
            </Button>
          </CenteredContainer>
        </Container>
        <CenteredContainer>
          <CallToAction
            image={strapiNaslovna.akcija.slika.localFile.childImageSharp.gatsbyImageData}
            bgurl={strapiNaslovna.akcija.pozadina.localFile.publicURL}
            title={strapiNaslovna.akcija.naslov}
            subtitle={strapiNaslovna.akcija.podnaslov}
            to={strapiNaslovna.akcija.dugme.url}
            label={strapiNaslovna.akcija.dugme.text}
            promocode={strapiNaslovna.akcija.dugme.iMikroSifraAkcije}
          />
        </CenteredContainer>
      </Layout>
    </Box>
  );
};

const LandingWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 96vh;
  box-sizing: border-box;
  ${({ theme }) => theme.breakpoints.down('xl')} {
    height: 92.5vh;
  }
`;

const StepContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacing(8)} -${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(8)};
  color: ${({ theme }) => theme.palette.primary.dark};
`;
const Step = styled.div`
  flex: 1;
  align-items: center;
  text-align: center;
  min-width: 180px;
  padding: ${({ theme }) => theme.spacing(4)};
`;
const PromoContainer = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  margin-top: ${({ theme }) => theme.spacing(10)};
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down('xl')} {
    margin-top: ${({ theme }) => theme.spacing(6)};
  }
`;

export const query = graphql`
  query IndexQuery {
    strapiNaslovna {
      heroSlides {
        id
        naslov
        podnaslov
        opcionalniTekst
        dugme {
          text
          url
          iMikroSifraAkcije
        }
        slika {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90, placeholder: NONE)
            }
          }
          alternativeText
        }
        thumbnail: slika {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, width: 64, height: 64)
            }
          }
          alternativeText
        }
        mobileSlika {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
            }
          }
          alternativeText
        }
        kontrastTekst
      }
      koraciNaslov
      koraciPodnaslov
      koraci {
        id
        naslov
        podnaslov
        ikona {
          localFile {
            publicURL
          }
        }
      }
      promoNaslov
      promoPodnaslov
      promoText
      promoAkcija {
        url
        text
        iMikroSifraAkcije
      }
      promoSlika {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 95, placeholder: NONE, width: 550)
          }
        }
      }
      akcija {
        naslov
        overlineText
        podnaslov
        dugme {
          text
          url
          iMikroSifraAkcije
        }
        slika {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 95, height: 460, placeholder: NONE)
            }
          }
        }
        pozadina {
          localFile {
            publicURL
          }
        }
      }
    }
    strapiCestaPitanja {
      naslov
      podnaslov
      ikona {
        localFile {
          publicURL
        }
      }
      faq {
        id
        odgovor
        pitanje
      }
    }
  }
`;

export default IndexPage;
