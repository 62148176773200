import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Link as RouterLink } from 'gatsby';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import { Box, Button, Typography } from '@mui/material';
import { ellipsis } from 'polished';

// eslint-disable-next-line
import { Swiper, SwiperSlide } from 'swiper/react';
// eslint-disable-next-line
import 'swiper/css';
// eslint-disable-next-line
import 'swiper/css/effect-fade';
// eslint-disable-next-line
import 'swiper/css/thumbs';
// eslint-disable-next-line
import SwiperCore, { Autoplay, EffectFade, Thumbs } from 'swiper';

// install Swiper modules
SwiperCore.use([Autoplay, EffectFade, Thumbs]);

const HeroSlider = ({ slides }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <>
      <SwiperWrapper>
        <Swiper
          effect="fade"
          fadeEffect={{ crossFade: true }}
          preloadImages={false}
          lazy={{ loadPrevNext: false }}
          className="swiperMain"
          speed={500}
          thumbs={{ swiper: thumbsSwiper }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
        >
          {slides.map(({ id, naslov, podnaslov, dugme, slika, mobileSlika }) => {
            const images = withArtDirection(getImage(slika.localFile), [
              { media: '(max-width: 576px)', image: getImage(mobileSlika?.localFile || slika.localFile) },
            ]);

            return (
              <SwiperSlide key={id}>
                <HeroWrapper>
                  <HeroContent>
                    <Typography variant="overline">{podnaslov}</Typography>
                    <Typography variant="h1" color="inherit" gutterBottom>
                      {naslov}
                    </Typography>
                    <Box mt={1}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        component={RouterLink}
                        to={dugme.url}
                        state={{ promocode: dugme.iMikroSifraAkcije }}
                      >
                        {dugme.text}
                      </Button>
                    </Box>
                  </HeroContent>
                </HeroWrapper>
                <GatsbyImage className="art-directed" image={images} alt="" />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </SwiperWrapper>
      <ThumbnailSliderWrapper>
        <Swiper onSwiper={setThumbsSwiper} className="thumbs-swiper" slidesPerView="auto" freeMode>
          {slides.map(({ id, naslov, thumbnail }, index) => {
            const image = getImage(thumbnail.localFile);
            return (
              <SwiperSlide key={id}>
                <ThumbnailWrapper>
                  <ThumbnailAvatar>
                    <AvatarImageWrapper>
                      <GatsbyImage image={image} alt="" />
                    </AvatarImageWrapper>
                  </ThumbnailAvatar>
                  <Typography variant="body2">{naslov}</Typography>
                </ThumbnailWrapper>
                {index < slides.length - 1 && <ThumbnailDivider />}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </ThumbnailSliderWrapper>
    </>
  );
};

const SwiperWrapper = styled.div`
  height: 100%;
  width: 100%;

  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .swiper-slide .gatsby-image-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .swiper-slide img {
    ${({ theme }) => theme.breakpoints.down('xl')} {
      object-position: 70%;
    }
  }
`;

const HeroWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  ${({ theme }) => `
    ${theme.breakpoints.down('xl')} {
      padding: 0 ${theme.spacing(3)};
      position: initial;
    }
  `}
  .MuiTypography-overline {
    margin-bottom: 8px;
  }
`;

const HeroContent = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 540px;
  padding-right: 104px;
  padding-left: ${({ theme }) => theme.spacing(3)};
  z-index: 999;

  ${({ theme }) => `
    ${theme.breakpoints.down('xl')} {
    padding: 0;
    justify-content: flex-start;
    padding-top: 104px;
  }
  `}
`;

const ThumbnailWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  p {
    max-width: 140px;
  }
  ${({ theme }) => theme.breakpoints.down('xl')} {
    justify-content: center;
    margin-right: 16px;
    p {
      ${ellipsis('96px')}
    }
  }
`;

const ThumbnailAvatar = styled.div`
  height: 64px;
  width: 64px;
  display: block;
  margin-right: 16px;
  margin-left: 32px;
  box-sizing: border-box;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  padding: 3px;

  &::after {
    position: absolute;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
    content: '';
    z-index: -1;
    border-radius: 50%;
    background: #eaeaea;
  }
`;

const AvatarImageWrapper = styled.div`
  padding: 3px;
  background: #fff;
  border-radius: 50%;
  img {
    border-radius: 50%;
  }
`;

const ThumbnailSliderWrapper = styled.div`
  height: 13.5vh;
  box-sizing: border-box;
  width: 100%;
  z-index: 10;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 12px;
  backdrop-filter: blur(16px);
  background: rgba(245, 244, 247, 0.8);
  padding: 0 16px;

  .thumbs-swiper {
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 1200px;
    height: 100%;
    margin: auto;
  }

  .thumbs-swiper .swiper-slide {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;

    ${({ theme }) => theme.breakpoints.down('xl')} {
      width: 48%;
    }

    @media (max-width: 400px) {
      width: 60%;
    }
  }

  ${ThumbnailWrapper} {
    opacity: 0.4;
  }

  .thumbs-swiper .swiper-slide-thumb-active {
    ${ThumbnailWrapper} {
      opacity: 1;
    }
  }

  .thumbs-swiper .swiper-slide-thumb-active {
    ${ThumbnailAvatar}::after {
      background: linear-gradient(to right, #8c64d2, #7342c8);
    }
  }
`;

const ThumbnailDivider = styled.div`
  height: 56px;
  width: 1px;
  display: block;
  background: rgba(0, 0, 0, 0.2);
`;

export default HeroSlider;
